.Progress-container {
  position: relative;
  height: 4px;
  width: 100%;
  background-color: #606060;
  top: 0;
  z-index: 10;
}

.Progress-bar {
  height: 100%;
  background-color: #6cd1ef;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(118, 199, 192, 0.5);
  transition: width 0.5s ease-in-out;
}
