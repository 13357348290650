.Metric-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #282c34;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.Metric-card:hover {
  transform: translateY(-5px);
}

.Metric-card p {
  margin: 0.5rem 0;
  font-weight: bold;
}

.Metric-card span {
  font-size: 1.2rem;
}

@media (max-width: 480px) {
  .Metric-card p,
  .Metric-card span {
    font-size: 0.8rem; /* Adjust as needed */
  }
  .Metric-card {
    padding: 0.5rem; /* Reduce padding for smaller screens */
  }
}
