.Metrics {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
}

.Metrics-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0.5rem;
}

@media (max-width: 480px) {
  .Metrics {
    margin: 1rem 0; /* reduce horizontal margins to 0 */
  }
}

.Metrics-error {
  background-color: #de5c5c;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem;
}

.Metrics-timestamp {
  font-weight: bold;
  font-family: monospace;
  font-size: 0.5rem;
  color: #3e3e3e;
}

.Metrics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin: 1rem 0;
}

/* For tablet screens */
@media (max-width: 768px) {
  .Metrics-grid {
    grid-template-columns: repeat(2, 1fr); /* Adjust to 2 cards per row */
  }
}

/* For mobile screens */
@media (max-width: 480px) {
  .Metrics-grid {
    grid-template-columns: repeat(2, 1fr); /* One card per row */
  }
}

.Temperature-cold {
  color: #0081a7;
}

.Temperature-cool {
  color: #00afb9;
}

.Temperature-normal {
  color: #fdfcdc;
}

.Temperature-warm {
  color: #fed9b7;
}

.Temperature-hot {
  color: #f07167;
}

.Humidity-dry {
  color: #ffa62b;
}
.Humidity-normal {
  color: #ede7e3;
}
.Humidity-humid {
  color: #82c0cc;
}
.Humidity-wet {
  color: #489fb5;
}

.Pressure-very-low {
  color: #e6e6fa;
}
.Pressure-low {
  color: #aec6cf;
}
.Pressure-normal {
  color: #b5eaaa;
}
.Pressure-high {
  color: #fffacd;
}
.Pressure-very-high {
  color: #ffd1dc;
}

.CO2-low {
  color: #b5eaaa; /* Pastel Green */
}

.CO2-moderate {
  color: #aec6cf; /* Pastel Blue */
}

.CO2-elevated {
  color: #fffacd; /* Pastel Yellow */
}

.CO2-high {
  color: #ffcc99; /* Pastel Orange */
}

.CO2-very-high {
  color: #ffd1dc; /* Pastel Pink */
}
