.ColorCodes {
  width: 100%; /* Full width by default */
  max-width: 30rem; /* Maximum width it can expand to */
  box-sizing: border-box; /* Ensures padding and border don't add up to the width */
  margin: 0 auto; /* Center the element if the viewport is wider than 30rem */
}

/* Media Queries for additional responsiveness */
@media screen and (max-width: 600px) {
  .ColorCodes {
    padding: 0.5rem; /* Reduced padding for small screens */
  }

  /* Additional style changes for other child elements can be added here if needed */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ColorCodes h2 {
  color: #fff;
  font-weight: 300; /* Using Lato Light */
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Lato", sans-serif; /* Using Lato font-family */
  font-size: 1.5rem;
  line-height: 1.2;
}

.ColorCodes-input {
  width: 100%;
  padding: 0.5rem;
  background-color: #282828; /* Darker background color for the input */
  border: 1px solid #444; /* Subtle border that's slightly lighter than the background */
  border-radius: 4px;
  font-size: 1rem;
  font-family: "Arial", sans-serif;
  color: #ddd; /* Lighter text color for contrast against the dark background */
  outline: none;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ColorCodes-input::placeholder {
  color: #888; /* Muted placeholder color */
}

.ColorCodes-input:focus {
  border-color: #007bff; /* A color pop when the input is focused */
}

.ColorCodes-colors {
  display: flex; /* To layout the child divs in a row */
  flex-wrap: wrap; /* To wrap the child divs if they exceed the container's width */
  border: 1px solid #444; /* This adds a 1px black border */
  padding: 1px; /* Create space for the inner white border */
  margin: 1rem; /* Add some space between the input and the colors */
}

.ColorCodes-colors > div {
  flex-grow: 1; /* Makes each div grow equally */
  height: 1rem; /* Retain the height setting */
  flex-basis: 0; /* Start without taking any space and then grow equally */
  animation: fadeIn 1s ease-out; /* Apply the fade-in animation */
}

.ColorCodes-colors > div:first-child,
.ColorCodes-colors > div:last-child {
  flex-grow: 0.5; /* Makes them take half the space of other divs */
}

.ColorCodes-button {
  background-color: #282828; /* Similar to the input's background but can be adjusted */
  color: #ddd; /* Similar to the input text color */
  padding: 0.5rem 1rem; /* Padding to give the button some space */
  font-family: "Lato", sans-serif; /* Consistent with the title */
  font-size: 1rem; /* Matching the text size of the input for consistency */
  border: 1px solid #444; /* A subtle border, similar to the input */
  border-radius: 4px; /* Rounded corners similar to the input */
  cursor: pointer; /* To indicate it's clickable */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  outline: none; /* Removes the browser default outline */
  margin-top: 1rem; /* Space between the button and the component above it */
}

/* Hover effect to provide feedback to the user */
.ColorCodes-button:hover {
  background-color: #383838; /* Slightly lighter shade for feedback */
  border-color: #555; /* Lighter border to contrast with the background */
}

/* Active state for when the button is pressed */
.ColorCodes-button:active {
  background-color: #1f1f1f; /* Slightly darker shade for the pressed effect */
  border-color: #333; /* Darker border to contrast with the background */
}

/* If you want to make it clear that it's clickable, you can also add a subtle box-shadow */
.ColorCodes-button:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
